
import React,{useState} from "react";
import "./style.css";
import { withRouter } from "react-router";
import LeftDrawer from "../../components/leftDrawer";
import DownloadPostButton from "../../components/DownloadPostButton";
import { Constants } from "../../config";

import {
    AiOutlineSearch,
  } from "react-icons/ai";
import ImageGallery from "../../components/MasonryListing";
import StorageService from "../../services/StorageService";
import S3 from "../../services/aws/s3";
import ApiService from "../../services/ApiService";
import ProfileImage from './uploadImage';
import { TYPE_COMBINED, APP_BIB_SEARCH,APP_FACE_SEARCH, APP_COMBINED, TYPE_SALES} from '../../config/constants'
import toast from "../../services/toast";
import { saveAs } from 'file-saver';
import urlConfig from "../../config/url-config";
// import ImageGallery from '../../components/MasonryListing/v1'
import helpers from "../../services/helpers";
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import AlertDialog from '../../components/Modal/v1'
import GenerateCertificateModal from '../../components/Modal/certificate' 

const downloadImages =(images) => {
    for (let image of images) {
        saveAs(image, 'eventforce-'+(Math.random() + 1).toString(36).substring(7)+'.jpg') // Put your image url here.
        // let response  = await  fetch(image.replace('https://dtq9cqmh745pp.cloudfront.net/','/cloudfront/',{headers:{"Access-Control-Allow-Credentials":true}}));
        // console.log(response.blob());
    }
  }

const SearchByBidNumber = ({imcId,searchBidNumber, isLoading, savedBid,onChange, isCertificate, selectCategory}) =>{
    const [bidNumber, setBidNumber] = useState(savedBid);
    const [customCertificate, setCustom] = useState(false);
    const [category, setCategory] = useState(null);


    const _handleCustomSearch = ()=>{
        if(category== null){
          return toast.error("Please select category");
        }
        searchBidNumber(bidNumber)
      }
    

    if(customCertificate){
      return(
<div >
             <div className="searchInputOuter">
              <select style={{borderRadius:4,width:'100%', marginTop:5}} onChange={e=>{{selectCategory(e.target.value); setCategory(e.target.value)}}}>
                <option value="">Select category</option>
                <option value={302}>3KM</option>
                <option value={5011}>5KM</option>
              </select>
              <input
                id="search"
                type="search"
                placeholder="Enter your name"
                autofocus
                required
                className="searchBidInput"
                onChange={(e) => {onChange(e.target.value);setBidNumber(e.target.value);}}
                value={bidNumber}
              />
            </div>
            {
                bidNumber.length > 0 && 
                <button
                onClick={_handleCustomSearch}
                className="searchBtn"            
            >{isLoading ? 'Searching...' : (isCertificate? 'Generate Certificate' : 'Next')}</button>

            || 
            <button
            onClick={()=>{}}
            className="searchBtn disabled-btn"            
            >Next</button>
            }
           
        </div>
      );
    }

    return(
        <div >
             <div className="searchInputOuter">
              <input
                id="search"
                type="search"
                placeholder="Enter your BIB no. here"
                autofocus
                required
                className="searchBidInput"
                onChange={(e) => {onChange(e.target.value);setBidNumber(e.target.value);}}
                value={bidNumber}
              />
            </div>
            {
                bidNumber.length > 0 && 
                <button
                onClick={()=>{searchBidNumber(bidNumber)}}
                className="searchBtn"            
            >{isLoading ? 'Searching...' : (isCertificate? 'Generate Certificate' : 'Next')}</button>

            || 
            <button
            onClick={()=>{}}
            className="searchBtn disabled-btn"            
            >Next</button>
            }
            {
              imcId==165 &&
           
            <div style={{paddingTop:10,cursor:'pointer',color:'#0000EE'}} onClick={()=>{setCustom(prev=>!prev);setBidNumber(''); }}>
                <p><a >You don't have any BIB?</a></p>
           </div>
            }
        </div>
    );
}


class FaceRecognitionSearch extends React.Component
{
  constructor(props){
    super(props);
    let campainData =  StorageService.getData("campaign_data")  ? JSON.parse(StorageService.getData("campaign_data")) : [];
    this.state = {
        isLoading:false,
        image:null,
        userDetails:    JSON.parse(StorageService.getData("user_details")),
        bidNumber:      StorageService.getData("bidNo") ? StorageService.getData("bidNo") : "",
        frEventId:      StorageService.getData("fr_event_id"),
        selectedImages: StorageService.getData("selectedImages")  ? JSON.parse(StorageService.getData("selectedImages")) : [],
        campaignData:  campainData,
        paymentSlab:   StorageService.getData("paymentSlab")  ? JSON.parse(StorageService.getData("paymentSlab")) : [],
        event_type:campainData?.is_sales_event,
        is_this_sales_event:campainData?.is_sales_event!=0,
        upToImageCount:0,
        sessionId:StorageService.getData('sessionId'),
        imageList:[],
        purchaseHistory:[],
        isSearchEmpty:false,
        showImageUpload:false,
        downloadUnbranded:false,
        isOpenSalesModal:false,
        bg:'#fff',
        totalPrice:0,
        is_dynamic_branding:campainData?.is_dynamic_branding,
        isFaceSearch:false,
        applicationType:campainData?.application,
        isPrebooked:false,
        isCertificate:Constants.IS_CERTIFICATE_APP,
        selectedCertificate:{},
        isOpenCertificate:false,
        showCertificateApp:false,
        customCertificate:false,
        categoryType:null,
        assingBidAfterButtinClick:StorageService.getData("bidNo") ? StorageService.getData("bidNo") : "",
    }
  }
   
  isPaymentNeeded(){
    
    return (this.state.event_type==TYPE_SALES || (this.state.event_type==TYPE_COMBINED && this.state.downloadUnbranded));
  }
    uploadImage(image){       
        const type = image.split(';')[0].split('/')[1];
        this.setState({isLoading:true});          
        let timestamp = + new Date();     
        S3.upload(image,timestamp+'.'+type,(data)=>{
            this.setState({isLoading:false});
            this.saveProfileLink(data.Location);
        });
    }

    saveProfileLink(link){
        let user =  this.state.userDetails;
        let postParams = {
            profile_image:link,
        };
        ApiService.postData("updateProfileImage", postParams, true).then(
            (response) => {
                if (response.status == "success") {
                    let newData = {
                        ...user,
                        profile_image:link,
                    };
                    StorageService.storeData("user_details", JSON.stringify(newData));
                    this.setState({isLoading:false, userDetails:newData});
                    this.handleSearch();
                }
            }).catch(e=>{
                this.setState({isLoading:false});
            })
    }

    componentDidMount(){
       
        this.handleSearch('initial');      
        this.calculateTotalPrice();

        if(this.state.event_type==TYPE_SALES || this.state.event_type==TYPE_COMBINED ) {
          this.getPaymentSlab();
        }else{
          this.setState({paymentSlab:{}});
        }
    }

    getPaymentSlab(){
      
      let event_id = this.state.frEventId;
      ApiService.imgConsoleGetData(`events/payment/participant/detailed-options?event_id=${event_id}`, {}, true).then(
        (response) => {
          let priceDetails = response[0];
          let priceSlab = Object.values(priceDetails)[0];
          priceSlab.id = Object.keys(priceDetails)[0];
          this.setState({paymentSlab: priceSlab});
        })
    }

    purchaseHistory() {
      ApiService.getData(`paymentHistory`, {event_id:this.state.campaignData?.image_console_id,bib_number:this.state.bidNumber},true).then((response) => {
        let upToImageCount = response?.data?.length!=undefined ? response?.data?.length : 0;
        this.setState({purchaseHistory:response.data,upToImageCount});
      })
    }
    /**
     * 
     * @param {*Link AWS URL} link
     * Post image to FR API 
     * 
     */
    async searchFaceRecognition()
    {
      let userImage = JSON.parse(StorageService.getData("user_details"))?.profile_image;
      if(!userImage) return false;
      this.setState({isFaceSearch:true});
      try{
      let file = await helpers.getS3ImageAsBinary(userImage);
      this.setState({isFaceSearch:true});
      let param = new FormData();
      param.append('file', new Blob([file]));
      param.append('event_id',this.state.frEventId);
      param.append('bib_number',this.state.bidNumber.toUpperCase());
     
        ApiService.frPostData("fr/search-faces", param, true).then(
          (response) => {
              this.setState({isFaceSearch:false,showImageUpload:false});
              if(response.length > 0){
                let responseImage = response[0].matches;
                this.setState(prevState=>({imageList:[...responseImage,...prevState.imageList]}));
                this.saveFrImages(responseImage);
            }else {
                toast.error("No result found using Face search");
            } 
           

            }).catch(e=>{this.setState({isFaceSearch:false})})
          }catch(err){
            this.setState({isFaceSearch:false});
            toast.error("Something went wrong. Plese try again");

        }
    }

     /**
     * This function used to save FR results in IMC Console
     */
     saveFrImages(response){
      let userImage = JSON.parse(StorageService.getData("user_details"))?.profile_image;

      let id = this.state.userDetails?.id;
      let params = response.map(item=>{
          return {
              participant_image:userImage,
              image_id:item.id,
              event_id:this.state.eventId,
              participant_id:id,
          }
      });
      ApiService.frPostData("fr/search-results", params, true).then(response=>{});
  }

    // render BIB Search
    _renderBibSearch(){
      return(
        <SearchByBidNumber imcId={this.state.campaignData?.image_console_id} isCertificate={this.state.isCertificate} onChange={(number)=>this.setState({bidNumber:number, isLoading:false})} selectCategory={(value)=>this.setState({categoryType:value})}  savedBid={this.state.bidNumber} isLoading={this.state.isLoading} searchBidNumber={(bidNumber)=>{
         
          this.setState({showCertificateApp:false,assingBidAfterButtinClick:bidNumber,isLoading:true},()=>{
            this.handleBibSearch();
          })
          
          // this.setState({showImageUpload:true,selectedImages:[]});
          // StorageService.storeData('selectedImages', JSON.stringify([]));
        }}/>
      );

    }

    //Face Search
    _renderFaceSearch(){
      return(
        <ProfileImage onCamChange={(value)=>{this.setState({bg:value ? '#000' : '#fff'})}}
        onSkipSearch={()=>{this.setState({showImageUpload:false});this.searchBidImages();}}
        skipBtn={this.state.applicationType!=APP_FACE_SEARCH}
        isFrSearching={this.state.isFaceSearch}
        infoContent={'This is to search the unidentified images'} 
        btnName={this.state.isFaceSearch ? 'Verifying facial match. Please wait....' : 'Face Search'} isLoading={this.state.isLoading} faceSearchImage={()=>this.handleSearch()} uploadImage={(image) => this.uploadImage(image)}
    /> 
      )
    }

    handleBibSearch(){
      if(this.state.isCertificate && this.state.campaignData?.is_image_certificate==0) {
        this.setState({showCertificateApp:true});
        return;
      }
      
      StorageService.storeData('selectedImages', JSON.stringify([]));
        if(this.state.applicationType==APP_COMBINED && !this.state.isCertificate){
          this.setState({showImageUpload:true});
        }else {
          this.setState({isBibLoading:true});
          this.searchBidImages('bib'); //BIB Search
        }
    }

    _renderResults(isForCertificate = false){
      return(
        <>
        {
          (this.state.imageList.length > 0 && this.state.event_type==TYPE_COMBINED && !isForCertificate) &&  
                        
          <div className="inlineSearchOuter" style={{display:'flex',alignItems:'center'}} >
           
               <div className="checkbox-form" onClick={()=>{
                 if(!this.state.downloadUnbranded){
                   this.setState({isOpenSalesModal:true})
                 }else {
                   this.setState({downloadUnbranded:false})
                 }
               }}>
                 {!this.state.downloadUnbranded ? <CheckBoxOutlineBlankIcon/> : <CheckBoxIcon/> }
                 <p>Download Unbranded Images</p>
                 
               </div>
             </div>
        }             

{
  (this.state.isPrebooked && !isForCertificate) &&
<div className="info-alert">
  <p> <p className="info-title">Great news!</p> Your event images are complimentary. As you've already prebooked this event, all event images are available to you at no additional cost. Relive your cherished memories with ease!</p>
</div>
}

        <ImageGallery
         onSelectCertificate={(item)=>this.setState({selectedCertificate:item})}
            isForCertificate={isForCertificate}
            selectedImages={this.state.selectedImages}
            onSelect={(image, isSelected)=>{
            if(isSelected) {
                this.setState({selectedImages:[...this.state.selectedImages,image]},()=>{StorageService.storeData('selectedImages', JSON.stringify(this.state.selectedImages));
                this.calculateTotalPrice();
            });
            } else {
                let selectedImages = this.state.selectedImages.filter(item => item.id!=image.id);
                this.setState({selectedImages},()=>{StorageService.storeData('selectedImages', JSON.stringify(this.state.selectedImages));this.calculateTotalPrice();});
            }              
            }}
        images={this.state.imageList}
    /> 
                     </>
      );
    }
    handleSearch(type="search")
    {
      if(this.state.isCertificate && this.state.campaignData?.is_image_certificate==0) {
        this.setState({showCertificateApp:true});
        return;
      }
     let sessionId = this.state.bidNumber+this.state.frEventId;
    
     StorageService.storeData('sessionId', sessionId);
     if(type!='initial') {
         StorageService.storeData('selectedImages', JSON.stringify([]));
         this.setState({selectedImages:[]});
     }
     StorageService.storeData('bidNo', this.state.bidNumber);
     
     if(this.state.applicationType!=APP_FACE_SEARCH && this.state.bidNumber){
       this.searchBidImages();
     }

     if(this.state.userDetails?.profile_image && this.state.applicationType!=APP_BIB_SEARCH) {
        this.setState({showImageUpload:true});
        this.searchFaceRecognition(this.state.userDetails?.profile_image);
      }
    }

    calculateTotalPrice()
  {
    if(this.state.isPrebooked){
      this.setState({totalPrice:0});
      return;
    }
    // let paymentFor = this.state.selectedImages.length - this.state.upToImageCount;
    // console.log(this.state.upToImageCount)
    let paymentFor = this.state.selectedImages.length + this.state.upToImageCount;
      this.setState({totalPrice: helpers.calculatePrice(paymentFor,this.state.upToImageCount)});     
  }

  
    searchBidImages(type='all'){
      ApiService.getData('checkPrebook',{},true,`?bib=${this.state.bidNumber}&event_id=${this.state.frEventId}`).then(res=>{
        const {data} = res;
        this.setState({isPrebooked:data.length > 0 ? true : false });
       
      }).catch(err=>{

      });


      this.purchaseHistory();
        this.setState({isLoading:true,isSearchEmpty:false, bidNumber:this.state.bidNumber,isOpenCertificate:false});
        ApiService.frGetData(`tagging/search?bib=${this.state.bidNumber.toUpperCase()}&event_id=${this.state.frEventId}&thumbnail=${this.state.is_this_sales_event ? 'true' : 'false'}`, {}, true).then(
            (response) => {
                setTimeout(()=>{ toast.show("To download images, simply click on the image you wish to download.");},3000);
                StorageService.storeData('bidNo', this.state.bidNumber);
                if(this.state.isCertificate){
                  this.setState({isOpenCertificate:true});
                }

                if(response.length > 0){
                  let responseImage = response[0].matches;
                  this.setState(prevState=>({imageList:[...responseImage.filter(item=>item.image!=""),...prevState.imageList],isSearching:false,isLoading:false,showResults:true,showImageUpload:false}));                                       
              }else {
                       this.setState({isLoading:false,isSearchEmpty:true});
                      toast.error("No result found using BIB search");
              }                         
                
                
                this.setState({bidNumber:this.state.bidNumber});
            }).catch(err=>{this.setState({isLoading:false}); console.log('Error', err)})
    }

    saveImagesToProfile(){
        let imagesIds = this.state.selectedImages.map(item=>item.id);
        let type = this.isPaymentNeeded() ?  'raw_images' : 'branded_images';
        let params  = 'image_id=['+imagesIds+']&event_id='+this.state.frEventId+'&type='+type;
        if(this.state.bidNumber){
          params += `&bib=${this.state.bidNumber.toUpperCase()}`; 
        }
          ApiService.imgConsoleGetData('images/search/cloud-urls?'+params).then(response=>{
              if(this.isPaymentNeeded()){
                this.saveToImageConsole();
              }else {
                this.saveToImageConsole('free');
              }
              let originalImages = response[0].matches;
              originalImages = originalImages.filter(item=>imagesIds.includes(item.id))
              let images = originalImages.map(item=>item.image);
              downloadImages(images);
              let params = {
                images: JSON.stringify(images),
              };
              this.uploadDownloadedImages(params);
          })
      
      }

      uploadDownloadedImages(params){
        ApiService.postData("uploadImages", params, true).then(
          (response) => {
            StorageService.storeData('selectedImages',JSON.stringify([]));
            this.setState({selectedImages:[]});
            if (response.status == "success") {
              this.props.history.push("/my-downloads?app_type=photo-app");
            } else {
              alert('Something went wrong. Please try again later');
            }
          }
        );
      }
      /*********************** Payment Gateway *********************/

      loadScript(src) {
        return new Promise((resolve) => {
          const script = document.createElement("script");
          script.src = src;
          script.onload = () => {
            resolve(true);
          };
          script.onerror = () => {
            resolve(false);
          };
          document.body.appendChild(script);
        });
      }
      
      async displayRazorpay() {       
        if(this.state.totalPrice < 1){
          this.saveImagesToProfile();
          return;
        } 
        this.setState({
          loader_message: "Please wait while we intiate the payment procedure",
        });
        this.setState({ is_loading: true });
    
        const stateVal = this.state;
    
        const res = await this.loadScript(
          "https://checkout.razorpay.com/v1/checkout.js"
        );
    
        if (!res) {
          alert("Razorpay SDK failed to load. Are you online?");
          return;
        }
    
        let postParams = {
          total_price: this.gstPrice(),
        };
    
        ApiService.postData("createOrder", postParams, true).then(
                  (response) => {
                    if(response.status_code ==200){
                      this.openPaymentGateWay(response?.data);
                    }
                    // console.log(response?.data);
                    // ;
                  });
                  return;
      
      }
        gstPrice(){
          let gstAmount = (this.state.totalPrice * 18) / 100;

          return Math.round(this.state.totalPrice + gstAmount);
        }
      openPaymentGateWay(data)
      {        
       
        let campaignData = this.state.campaignData;
        const options = {
          // key: __DEV__ ? Constants.RAZORPAY_KEY_DEV : Constants.RAZORPAY_KEY_PROD,
          key: Constants.RAZORPAY_KEY,
          currency: data.currency,
          amount: data.amount.toString(),
          order_id: data.id,
          name: campaignData?.title ? campaignData?.title
            : "Eventforce",
          description: "Thank you",
          // image: imgPath + "/logo.dff71fb7.png",
          image: campaignData?.logo,
          handler: (response) => {
        
            if (response) {
              this.setState({
                loader_message: "Please wait while we prepare your photos",
              });
    
              let imagesArr = this.state.selectedImages.map((a) => a.id);
              const paymentDetails = {
                amount: data.amount.toString(),
                gateway: "razorpay",
                transaction_id: response.razorpay_payment_id,
                order_id: response.razorpay_order_id,
                payment_id: response.razorpay_payment_id,
                gateway_response: response,
                status: 1,
              };
    
              let postParams = {
                payment_gateway_response: JSON.stringify(paymentDetails),
                status: 1,
                transaction_id: response.razorpay_payment_id,
                images: JSON.stringify(imagesArr),
                total_qty: this.state.selectedImages.length,
                total_price: this.gstPrice(),
                gateway_amount:data.amount,
                image_console_id:campaignData.image_console_id,
                bib_number:this.state.bidNumber,
              };
              ApiService.postData("createPhotoOrder", postParams, true).then(
                (response) => {
                  if (response.status == "success") {
                      this.saveImagesToProfile();
                  } else {
                  }
                }
              );
            }
          },
          modal: {
            ondismiss: () => {
              alert("Payment cancelled. Try again");
              this.setState({ is_loading: false });
            },
          },
          prefill: {
            name: this.state.user_name,
            email: this.state.user_email,
          },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
      }

      /*********************** Payment Gateway *********************/

      /*********************** Save Images to Image Console ************/
      saveToImageConsole(type='paid'){
        let data ={
          customer_id:this.state.userDetails?.id,
          payment_model_id: type== 'free' ? null : parseInt(this.state.paymentSlab?.id),
          unique_identifier:helpers.generateRandomString(16),
          event_id:this.state.frEventId,
          amount:type== 'free' ? 'FREE' : this.state.totalPrice,
        }
        let imagesArr = this.state.selectedImages.map((a) => a.id);
        imagesArr.map(imageIds =>{
          data.image_id = imageIds;
         
            ApiService.imgConsolePostData("images/downloads", data).then(
              (response) => {
              // console.log(response);
              }
            );

        })



      }


    render(){
        return(
            <div className="pageOuter" style={{background:this.state.bg}}>   
                    <div className="privacyGreenBlock">
                        <div className="headerOuter">
                            <LeftDrawer></LeftDrawer>
                            <h4 className="headerTitle"></h4>
                        </div>
                        <AlertDialog
                        handleOkay={()=>{this.setState({isOpenSalesModal:false,downloadUnbranded:true})}}
                        handleClose={()=>{this.setState({isOpenSalesModal:false,downloadUnbranded:false})}}
                        isOpen={this.state.isOpenSalesModal} content={'To access the original images, payment for the selected ones is necessary. Swipe up on the payment button or click on the price info to view the pricing details.'} title={'Payment for Unbranded images'}/>
                        <div className="ppOuter">
                            <h4 className="ppTitle">Find your photo</h4>
                            <h3 className="ppTitle"></h3>
                        </div>   
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                        {
                            (this.state.imageList.length > 0 )&&
                            <div className="inlineSearchOuter">
                                <button className="inlineSearchBtn" style={{marginTop:10,width:'50px'}}>
                                    <AiOutlineSearch
                                    className="searchIcon"
                                    onClick={() => this.setState({imageList:[],selectedImages:[],showImageUpload:false})}
                                    />
                                </button>               
                            </div>
                        }         
                        </div>
                    </div>

                    <div style={{paddingTop:20,}}>  
                    <div style={{paddingRight:20,paddingLeft:20}}>
                      {(this.state.isFaceSearch && this.state.imageList.length > 0) &&  <p style={{fontSize:12,textAlign:'center'}}>Searching for matching faces. Please hold on...</p> }
                        {/* BIB Search */}
                          {(this.state.applicationType!=APP_FACE_SEARCH && this.state.imageList.length < 1 && !this.state.showImageUpload) && this._renderBibSearch()}
                        {/* //BIB Search */}

                        {/* Face Search */}
                          {((this.state.applicationType==APP_FACE_SEARCH && this.state.imageList.length < 1 ) || (this.state.applicationType==APP_COMBINED && this.state.showImageUpload)) && this._renderFaceSearch()}
                        {/* //Face Search */}

                      </div>
                        {/* Generate Certificate */}                       
                      
                        {/* <div className="innerPadding cBtnWraper">
                          
                            <button className="guest-search-btn" onClick={()=>{this.setState({isOpenCertificate:true})}}>Generate Certificate</button>
                        
                        </div> */}
                        <GenerateCertificateModal
                        categoryType={this.state.categoryType}
                        campaignData={this.state.campaignData}
                        campaignId={this.state.campaignData?.id} 
                        bidNumber={this.state.assingBidAfterButtinClick}
                        frEventId={this.state.frEventId}
                        imageLoaded={()=>this.setState({isLoading:false})}
                        showCertificateApp={this.state.showCertificateApp}
                        type={'raw_images'}
                        cancelFn={()=>{;this.setState({showCertificateApp:false, isLoading:false})}}
                        handleClick={(value, isClear = false)=>{
                          if(isClear){
                            this.setState({selectedCertificate:{},imageList:[],selectedImages:[],showImageUpload:false});
                          }
                          this.setState({isOpenCertificate:value,selectedCertificate:{}});
                        }} isOpen={this.state.isOpenCertificate} 
                        title={'Select Image'}
                         selectedCertificate={this.state.selectedCertificate}>
                         {this.state.imageList.length > 0 ? this._renderResults(true) : <p>Loading...</p>}


                        </GenerateCertificateModal>
                                               
                      {/* Generate Certificate */}

                       
                        {/* Results */}
                          {(this.state.showResults || !this.state.isFaceSearch) && (this.state.imageList.length > 0) && !this.state.isCertificate && this._renderResults() }
                        {/* //Results */}                         
               
             


        {this.state.selectedImages.length > 0 &&
        <DownloadPostButton
              is_this_sales_event ={this.state.is_this_sales_event}
                count={this.state.selectedImages.length}
                type={this.isPaymentNeeded() ? "" : 'btn_save'}
                totalPrice={this.state.totalPrice}
                paymentSlab={this.state.paymentSlab}
                purchasedImages={this.state.upToImageCount}
                onClickDownloadButton={()=> this.isPaymentNeeded() ? this.displayRazorpay() : this.saveImagesToProfile()}
                endText={(this.isPaymentNeeded() && this.state.totalPrice > 0) ? "Pay Now" : "Download"}
                />
          }                    
                </div>
            </div>
        )
    }
}

export default withRouter(FaceRecognitionSearch);
